import React from "react";
import PropTypes from "prop-types";

export default function ADefault({
	className,
	children,
}) {
	return (
		<span className={`a-chip ${className}`}>
			{children}
		</span>
	);
}

ADefault.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any,
};

ADefault.defaultProps = {
	className: "",
	children: undefined,
};
