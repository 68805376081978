import { storageConfig } from "../config";

function getValueFromKey(key) {
	const result = localStorage.getItem(key);

	return JSON.parse(result);
}
function storeState(value) {
	const result = JSON.stringify(value);

	return localStorage.setItem(storageConfig.keys.state, result);
}

export default {
	initState: storeState,
	setState(value) {
		const state = getValueFromKey(storageConfig.keys.state);

		storeState({
			...state,
			...value,
			user: {
				...(state?.user || []),
				...(value?.user || []),
			},
		});
	},
	getState: () => getValueFromKey(storageConfig.keys.state),

	//SESSION STORAGE
	getUniqueIdentifier: () => sessionStorage.getItem(storageConfig.keys.uniqueIdentifier),
	setUniqueIdentifier: (value) => sessionStorage.setItem(storageConfig.keys.uniqueIdentifier, value),
};
