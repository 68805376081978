import React from "react";
import PropTypes from "prop-types";
import { keyEvents } from "../../../helpers";

export default function AButton({
	disabled,
	onClick,
	className,
	children,
	...props
}) {
	const handleClick = () => {
		if (!disabled && onClick) {
			onClick();
		}
	};
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			handleClick();
		}
	};

	return (
		<button
			{...props}
			disabled={disabled}
			className={`a-button ${className}`}
			onClick={handleClick}
			onKeyUp={handleKeyUp}
		>
			{children}
		</button>
	);
}

AButton.propTypes = {
	type: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

AButton.defaultProps = {
	type: "button",
	disabled: false,
	className: "",
	onClick: undefined,
};
