import React from "react";
import PropTypes from "prop-types";

import { Image } from "../../atoms";
import { FormattedMessage } from "react-intl";

export default function MPoweredBy({
	label,
	logo,
	className,
}) {
	return (
		<footer className={`m-footer m-footer--powered-by ${className}`}>
			{label && <p><FormattedMessage id={label} /></p>}
			<Image.ADefault src={logo} className="footer-image" />
		</footer>
	);
}

MPoweredBy.propTypes = {
	label: PropTypes.string,
	logo: PropTypes.string,
	className: PropTypes.string,
};

MPoweredBy.defaultProps = {
	label: "",
	logo: "",
	className: "",
};
