import React from "react";
import PropTypes from "prop-types";

import { logoSmall } from "../../../assets/image";

export default function ALoader({ className }) {
	return <div className={`a-component--main-loader ${className}`}>
		<div className="icon-loader loader-animation" />
		<img src={logoSmall} alt="" className="loader-logo" />
	</div>;
}

ALoader.propTypes = {
	className: PropTypes.string,
};

ALoader.defaultProps = {
	className: "",
};
