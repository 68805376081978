export default {
	SETTINGS_IS_FETCHING: "SETTINGS_IS_FETCHING",
	FETCHING_SETTINGS_FAILED: "FETCHING_SETTINGS_FAILED",
	FETCHING_SETTINGS_SUCCEEDED: "FETCHING_SETTINGS_SUCCEEDED",

	INIT_STATE: "INIT_STATE",

	UPDATE_STYLE: "UPDATE_STYLE",
	FETCH_SETTINGS: "FETCH_SETTINGS",

	SET_LANGUAGE: "SET_LANGUAGE",
	INIT_FONT: "INIT_FONT",
};
