import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "../App";
import { globalHeaders } from "../helpers";
import store from "../store/store";
import { actions as contentActions } from "../store/content";
import { actions as widgetActions } from "../store/widget";

export default {
	init({ apiKey, origin, state, identityId, contactId }) {
		globalHeaders.setHeaders({ apiKey, origin, identityId });

		store.dispatch(contentActions.settings.initState(state));
		store.dispatch(widgetActions.credentials.initCredentials({ apiKey, identityId, contactId, origin }));
		store.dispatch(contentActions.settings.fetchSettings());

		ReactDOM.render(
			<Provider store={store}>
				<App />
			</Provider>,
			document.getElementById("root"));
	},
};
