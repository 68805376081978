import { combineReducers } from "redux";

import {
	reducers as credentialsReducer,
	actions as credentialsActions,
} from "./credentials";

import {
	reducers as iframeReducer,
	actions as iframeActions,
} from "./iframe";

export const actions = {
	credentials: credentialsActions,
	iframe: iframeActions,
};

export const reducers = combineReducers({
	credentials: credentialsReducer,
	iframe: iframeReducer,
});
