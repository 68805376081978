import axios from "axios";

import actionTypes from "./settings.actionTypes";
import { apiRoutesConfig, initialConfig } from "../../../config";
import { customStyleHelpers, storageHelpers } from "../../../helpers";
import { actions as widgetActions } from "../../widget";

export const isFetching = () => (dispatch) => {
	dispatch({
		type: actionTypes.SETTINGS_IS_FETCHING,
	});
};
export const fetchingFailed = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCHING_SETTINGS_FAILED,
	});
};
export const fetchingSucceeded = () => (dispatch) => {
	dispatch({
		type: actionTypes.FETCHING_SETTINGS_SUCCEEDED,
	});
};

export const initState =
	(data = []) =>
	(dispatch) => {
		const state = storageHelpers.getState();

		if (!state) {
			storageHelpers.setState(initialConfig.localState);
		}

		if (state?.style?.font) {
			dispatch(initFont(state.style.font));
		}

		dispatch(widgetActions.iframe.sendSettingsUpdate(data));
		dispatch({
			type: actionTypes.INIT_STATE,
			data,
		});
	};

export const updateStyle =
	({ key, value }) =>
	(dispatch) => {
		const data = { [key]: Number(value) };

		dispatch({
			type: actionTypes.UPDATE_STYLE,
			data,
		});
		dispatch(widgetActions.iframe.sendSettingsUpdate());
	};

export const fetchSettings = () => async (dispatch) => {
	dispatch(isFetching());

	try {
		const result = await axios.get(apiRoutesConfig().getSdkSettings);

		if (result.status !== 200) {
			dispatch(fetchingFailed());
			return;
		}

		const { settings, flows } = result.data;

		const data = {
			branding: {
				brandColor: settings?.style?.primary,
				accentColor: settings?.style?.secondary,
				textColor: settings?.style?.text,
				logo: settings?.organization?.branding?.icon,
			},
			chatbot: {
				name: settings?.agent?.name,
				avatar: settings?.agent?.avatar,
			},
			settings: {
				languages: settings?.languages,
			},
			team: {
				_id: settings?.organization?.id,
				id: settings?.organization?.id,
				name: settings?.organization?.name,
				slug: settings?.organization?.slug,
				branding: {
					brandColor: settings?.style?.primary,
					accentColor: settings?.style?.secondary,
					textColor: settings?.style?.text,
					logo: settings?.organization?.branding?.icon,
				},
			},
		};

		dispatch({
			type: actionTypes.FETCH_SETTINGS,
			data: { data, flows },
		});

		dispatch(initFont(data?.chatbot?.style?.font));
		dispatch(widgetActions.iframe.sendSettingsUpdate(data));
		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const setLanguage = (lang) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_LANGUAGE,
		data: lang,
	});
};

export const initFont = (payload) => async (dispatch) => {
	try {
		if (!payload) {
			return;
		}

		const font = new FontFace(payload.name, `url(${payload.url})`);
		const loadedFace = await font.load();

		document.fonts.add(loadedFace);
		customStyleHelpers.changeFontFamily(`"${payload.name}"`);
		dispatch({ type: actionTypes.INIT_FONT });
	} catch (error) {
		console.error(error);
	}
};
