import { combineReducers } from "redux";

import {
	actions as settingsActions,
	reducers as settingsReducer,
} from "./settings";

export const actions = {
	settings: settingsActions,
};

export const reducers = combineReducers({
	settings: settingsReducer,
});
