// A
// B
// C
import * as MCard from "./molecules.card";
// D
// E
// F
import * as MFooter from "./molecules.footer";
// G
// H
import * as MHeader from "./molecules.header";
// I
// J
// K
// L
// M
// N
// O
// P
// Q
// R
// S
// T
// U
// V
// W
// X
// Y
// Z

export const Card = MCard;
export const Footer = MFooter;
export const Header = MHeader;
