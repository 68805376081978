import axios from "axios";

export default {
	setHeaders({ apiKey, identityId, origin }) {
		axios.defaults.headers.common = {
			"contentType":"application/json; charset=utf-8",
			"Authorization": `Bearer ${apiKey}`,
			"parentOrigin": origin,
			"dataType":"json",
			identityId,
		};
	},
};
