export default {
	flow: {
		header: {
			title: "flow.header.title",
			description: "flow.header.description",
		},
		footer: {
			label: "flow.footer.label",
			logo: "https://storage.googleapis.com/bothive_assets_8a0s1s3e/pdf_templates/default/logo.svg",
		},
	},
};
