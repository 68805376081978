import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { keyEvents } from "../../../helpers";
import { Button, Image } from "../../atoms";

export default function MTemplateCard({ id, title, description, logo, label, disabled, className, onClick }) {
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			handleClick(id);
		}
	};
	const handleClick = () => {
		if (onClick && !disabled) {
			onClick(id);
		}
	};

	return (
		<article
			role="button"
			tabIndex="0"
			data-disabled={disabled}
			onClick={handleClick}
			onKeyUp={handleKeyUp}
			className={`m-card m-card--template ${className}`}
		>
			<div className="card-content">
				<Image.ADefault src={logo} className="card-image" />
				<div className="card-body">
					<p className="card-title">{title}</p>
					<p className="card-description">{description}</p>
				</div>
			</div>
			{label && !disabled && (
				<Button.ANormal className="small card-button">
					<FormattedMessage id={label} />
				</Button.ANormal>
			)}
		</article>
	);
}

MTemplateCard.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string,
	description: PropTypes.string,
	logo: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

MTemplateCard.defaultProps = {
	title: "",
	description: "",
	logo: "",
	label: undefined,
	disabled: false,
	comingSoon: false,
	className: "",
	onClick: undefined,
};
