const initStyle = {
	position: "fixed",
	bottom: "0",
	right: "0",
	top: "auto",
	border: "none",
};

export default {
	listener: {
		closeTab: "closeTab",
		desktopNotificationPermission: "desktopNotificationPermission",
		initializeWidget: "initializeWidget",
		identityVerified: "identityVerified",
		languageChange: "languageChange",
		locationChange: "locationChange",
		closeWidget: "closeWidget",
		openWidget: "openWidget",
		parentSize: "parentSize",
		refresh: "refresh",
		urlChange: "urlChange",
	},
	emitter: {
		cancelLocationWatch: "cancelLocationWatch",
		showWidget: "showWidget",
		hideWidget: "hideWidget",
		newMessage: "newMessage",
		requestDeskNotificationPermission: "requestDeskNotificationPermission",
		resetMessages: "resetMessages",
		requestLocation: "requestLocation",
		requestParentSize: "requestParentSize",
		sendDesktopNotification: "sendDesktopNotification",
		settingsChange: "settingsChange",
		startTabbarNotification: "startTabbarNotification",
		stopTabbarNotification: "stopTabbarNotification",
		styleChange: "styleChange",
		widgetInit: "widgetInit",
	},
	style: {
		close: {
			...initStyle,
			height: "90px",
			minHeight: "90px",
			width: "100px",
			right: "0px",
			left: "auto",
		},
		open: {
			...initStyle,
			height: "100%",
			minHeight: "200px",
			maxHeight: "80vh",
			width: "420px",
			right: "0px",
		},
		mobileOpen: {
			...initStyle,
			height: "100%",
			minHeight: "100%",
			width: "100%",
			minWidth: "100%",
			top: "0",
			left: "0",
			right: "0px",
			zIndex: "9999999",
		},
	},
};
