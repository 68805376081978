import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

export default function MHeaderDescription({
	title,
	// description,
	className,
}) {
	return (
		<header className={`m-header m-header--description ${className}`} >
			<h1><FormattedMessage id={title} /></h1>
			{/* <p><FormattedMessage id={description} /></p> */}
		</header>
	);
}

MHeaderDescription.propTypes = {
	title: PropTypes.string.isRequired,
	// description: PropTypes.string.isRequired,
	className: PropTypes.string,
};

MHeaderDescription.defaultProps = {
	className: "",
};
