export default {
	widget: {
		defaultValue: "circle",
	},
	mobileWidth: 560,
	color: {
		brandFontColor: {
			primary: "#FFFFFF",
			fallback: "#000000",
		},
		minimumContrastRation: 2,
	},
};
