import actionTypes from "./settings.actionTypes";
import { initialConfig } from "../../../config";

const initialState = {
	chatbot: undefined,
	team: undefined,
	settings: undefined,
	style: {
		fontsize: 1,
		contrast: 2,
	},
	language: initialConfig.language,
	menu: {
		suggestionsEnabled: true,
		takeOverEnabled: false,
	},
	flows: [],
	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.SETTINGS_IS_FETCHING:
			return {
				...state,
				isFetching: true,
				fetchingFailed: false,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCHING_SETTINGS_FAILED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: true,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCHING_SETTINGS_SUCCEEDED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: false,
				fetchingSucceeded: true,
			};
		case actionTypes.INIT_STATE:
			return {
				...state,
				...action.data,
				user: undefined,
			};
		case actionTypes.UPDATE_STYLE:
			return {
				...state,
				style: {
					...state.style,
					...action.data,
				},
			};
		case actionTypes.FETCH_SETTINGS: {
			const uniqueFlowList = [];
			const flows = [...state.flows, ...action.data.flows];
			const uniqueIds = new Set(flows.map((flow) => flow.id));

			uniqueIds.forEach((flowId) => uniqueFlowList.push(flows.find((flow) => flow.id === flowId)));

			return {
				...state,
				...action.data.data,
				user: undefined,
				language:
					action.data.data.language || state.language || initialConfig.language,
				style: {
					...state.style,
					...action.data.data.chatbot?.style,
				},
				menu: {
					...state.menu,
					...action.data.data.menu,
					suggestionsEnabled: !action.data.data.settings.isLiveChatOnly,
					takeOverEnabled: action.data.data.settings.isLiveChatEnabled,
				},
				flows: uniqueFlowList,
			};
		}
		case actionTypes.TOGGLE_LIVE_CHAT_OPTION:
			return {
				...state,
				menu: {
					...state.menu,
					takeOverEnabled: action.data,
				},
			};
		case actionTypes.SET_LANGUAGE:
			return {
				...state,
				language: action.data,
			};
		default:
			return state;
	}
}
