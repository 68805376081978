import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};
const middleware = [thunk];
// eslint-disable-next-line no-undef
const devTools = process.env.NODE_ENV === "production" ?
	applyMiddleware(...middleware) : composeWithDevTools(applyMiddleware(...middleware));

const store = createStore(
	rootReducer,
	initialState,
	devTools,
);

export default store;
