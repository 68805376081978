import React from "react";
import PropTypes from "prop-types";

export default function ADefault({
	src,
	alt,
	className,
}) {
	return (
		<img
			alt={alt}
			src={src}
			className={`a-image a-image--default ${className}`}
		/>
	);
}

ADefault.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string,
	className: PropTypes.string,
};

ADefault.defaultProps = {
	alt: "",
	className: "",
};
