import { frontendHubConfig } from "../config";
import { globalHeaders } from "../helpers";
import store from "../store/store";

import { actions as contentActions } from "../store/content";
import { actions as widgetActions } from "../store/widget";

export default {
	initListener(initCallback) {
		let init = false;

		window.addEventListener("message", (event) => {
			const { type, payload } = event.data;
			// const state = store.getState().content; //TODO domain lock
			// const allowedDomains = [
			// 	...state?.settings?.settings?.trustedDomains || [],
			// 	...domainsConfig.alwaysAllowedOrigins,
			// ].map(item => formatHelpers.cleanUrl(item));

			// uncomment for debug
			// console.groupCollapsed(type);
			// console.log(payload);
			// console.groupEnd();

			// if (type !== frontendHubConfig.listener.initializeWidget &&
			// 	(!event.origin ||
			// 		!allowedDomains?.includes(formatHelpers.cleanUrl(event.origin)))) {
			// 	console.log("not allowed domain", allowedDomains);
			// }

			switch (type) {
				case frontendHubConfig.listener.initializeWidget:
					initCallback({
						identityId: payload.identityId,
						contactId: payload.contactId,
						apiKey: payload.apiKey,
						origin: event.origin,
						state: payload.state,
						data: payload.data,
					});

					if (payload.data && payload.data.language) {
						store.dispatch(contentActions.settings.setLanguage(payload.data.language));
					}

					init = true;
					break;
				case frontendHubConfig.listener.identityVerified:
					if (!init) {
						initCallback({
							identityId: payload.identityId,
							contactId: payload.contactId,
							apiKey: payload.apiKey,
							origin: event.origin,
							state: payload.state,
							data: payload.data,
						});
						init = true;
						break;
					}

					globalHeaders.setHeaders({
						origin: event.origin,
						apiKey: payload.apiKey,
						identityId: payload.identityId,
					});

					store.dispatch(widgetActions.credentials.initCredentials({
						origin: event.origin,
						apiKey: payload.apiKey,
						identityId: payload.identityId,
						contactId: payload.contactId,
					}));

					break;
				case frontendHubConfig.listener.languageChange:
					store.dispatch(contentActions.settings.setLanguage(payload.language));
					break;
				case frontendHubConfig.listener.refresh:
					store.dispatch(contentActions.settings.fetchSettings());
					break;
				default:
					break;
			}
		});
	},
};
