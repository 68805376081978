export default {
	localState: {
		team: undefined,
		settings: undefined,
		style: undefined,
		language: undefined,
	},
	hubState: {
		team: undefined,
		settings: undefined,
		language: "nl-BE",
	},
	language: "nl-BE",
};
