import cssVars from "css-vars-ponyfill";
import getContrast from "get-contrast";

import { styleConfig } from "../config";

function getFontColor({ backgroundColor, colors, contrast = styleConfig.color.minimumContrastRation }) {
	return getContrast.ratio(backgroundColor, colors.primary) > contrast ? colors.primary : colors.fallback;
}

export default {
	getFontColor,
	generateSettingsStyle(settings) {
		if (!settings) {
			return;
		}

		cssVars({
			variables: {
				textColor: settings.textColor,
				textBrandColor: getFontColor({
					backgroundColor: settings.brandColor,
					colors: styleConfig.color.brandFontColor,
				}),
				brandColorPrimary: settings.brandColor,
				accentColor: settings.accentColor,
			},
		});
	},
	changeFontFamily(fontFamily) {
		cssVars({
			variables: {
				fontFamily,
			},
		});
	},
};
