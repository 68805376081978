import actionTypes from "./credentials.actionTypes";

const initialState = {
	apiKey: "1541507771627-ZgWVH0C28RO4JBP34WazhImXBwRHWRzo",
	identityId: undefined,
	contactId: undefined,
	contact: undefined,
	origin: undefined,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.INIT_CREDENTIALS:
			return {
				...state,
				...action.data,
			};
		default:
			return state;
	}
}
