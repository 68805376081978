const localAllwaysAllowedOrigins = [
	"http://localhost:3000",
	"http://localhost:5000",
	"http://localhost:8000",
];
const devAllwaysAllowedOrigins = [
	"https://development.bothive.app",
	"https://dashboard.development.chatbots.gentwebart.be",
	"https://dashboard.development.bothive.be",
	"https://main.widget.development.bothive.be",
	"https://notification.widget.development.bothive.be",
];
const stagingAllwaysAllowedOrigins = [
	"https://staging.bothive.app",
	"https://dashboard.staging.bothive.be",
	"https://dashboard.staging.chatbots.gentwebart.be",
	"https://main.widget.staging.bothive.be",
	"https://notification.widget.staging.bothive.be",
];
const prodAllwaysAllowedOrigins = [
	"https://dashboard.bothive.be",
	"https://preview.bothive.be",
	"https://bothive.app",
];

let alwaysAllowedOrigins = localAllwaysAllowedOrigins;

switch (process.env.REACT_APP_ORIGIN_ALLOW_ENV) { //eslint-disable-line no-undef
	case "production":
		alwaysAllowedOrigins = prodAllwaysAllowedOrigins;
		break;
	case "staging":
		alwaysAllowedOrigins = stagingAllwaysAllowedOrigins;
		break;
	case "development":
		alwaysAllowedOrigins = devAllwaysAllowedOrigins;
		break;
	default:
		break;
}

export default {
	alwaysAllowedOrigins,
};
