import React from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { Helmet } from "react-helmet";

import "./store/store";

import translation from "./translation";
import Routes from "./routes";
import { customStyleHelpers } from "./helpers";

import "./assets/style/style.scss";

const mapStateToProps = (state) => ({
	credentials: state.widget.credentials,
	settings: state.content.settings,
	socket: state.socket,
});

function App({ settings }) {
	const lang = settings?.language?.slice(0, 2) || "nl";

	return (
		<IntlProvider locale={lang} messages={translation[lang]} key={lang}>
			<React.Fragment>
				<Helmet>
					<style type="text/css">{customStyleHelpers.generateSettingsStyle(settings?.team?.branding)}</style>
				</Helmet>
				<Routes />
			</React.Fragment>
		</IntlProvider>
	);
}

export default connect(mapStateToProps, {})(App);
