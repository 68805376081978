export default (overwriteHost = undefined) => {
	const host =
		overwriteHost ||
		process.env.REACT_APP_API_BASE_URL ||
		"http://localhost:8980"; // eslint-disable-line no-undef
	const baseUrl = `${host}/bot/v1`;

	return {
		host,
		getSdkSettings: `${baseUrl}/flows/settings`,
		checkApiKey: `${baseUrl}/team/apiKey`,
	};
};
