import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";

import history from "./history";
import { routesConfig } from "../config";
import { OverviewPage } from "../pages";

const Routing = () => (
	<Router history={history}>
		<Switch>
			<Route path={routesConfig.root} component={OverviewPage} />
		</Switch>
	</Router>
);

export default connect()(Routing);
