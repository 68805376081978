import React from "react";
import { connect } from "react-redux";

import { pageConfig } from "../config";
import { Footer, Card } from "../components/molecules";
import { Layout } from "../components/templates";

const mapStateToProps = (state) => ({
	flows: state.content.settings.flows,
	language: state.content.settings.language.slice(0, 2),
	isFetching: state.content.settings.isFetching,
	fetchingSucceeded: state.content.settings.fetchingSucceeded,
});

class OverviewPage extends React.Component {
	componentDidMount() {
		this.bindHandles();
	}

	bindHandles() {
		this.handleCardClick = this.handleCardClick.bind(this);
	}

	handleCardClick(id) {
		const flow = this.props.flows.find((item) => item.id === id);

		window.open(flow.link);
	}

	render() {
		if (this.props.isFetching || !this.props.flows) {
			return <Layout.TLoadScreen />;
		}

		return (
			<main className="t-layout t-layout--overview">
				{/* <Header.MHeaderDescription
					{...pageConfig.flow.header}
				/> */}
				<div className="layout-content layout-content--no-title">
					<div className="t-list t-list--cards">
						{this.props.flows.map((item) => (
							<li className="list--item" key={item.id}>
								<Card.MTemplateCard
									id={item.id}
									logo={item?.widgetSettings?.logo || item.template.logo}
									title={
										item?.widgetSettings?.name ||
										item.template.name[this.props.language]
									}
									description={
										item?.widgetSettings?.description ||
										item.template.shortDescription[this.props.language]
									}
									label="flow_template_modal.card.button.select"
									onClick={this.handleCardClick}
								/>
							</li>
						))}
					</div>
				</div>
				<Footer.MPoweredBy {...pageConfig.flow.footer} />
			</main>
		);
	}
}

export default connect(mapStateToProps, {})(OverviewPage);
