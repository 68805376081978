import actionTypes from "./iframe.actionTypes";

const initialState = {};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.SEND_NEW_MESSAGE:
		case actionTypes.SEND_SETTINGS_UPDATE:
		default:
			return state;
	}
}
