import actionTypes from "./iframe.actionTypes";
import { frontendHubConfig } from "../../../config";
import { frontendHubHelpers } from "../../../helpers";
import store from "../../store";

export const sendNewMessage = (message) => dispatch => {
	dispatch({ type: actionTypes.SEND_NEW_MESSAGE });

	frontendHubHelpers.sendToParent({
		type: frontendHubConfig.emitter.newMessage,
		payload: { message },
	});
};

export const sendSettingsUpdate = () => dispatch => {
	dispatch({ type: actionTypes.SEND_SETTINGS_UPDATE });

	const settingsState = store.getState().content.settings;

	frontendHubHelpers.sendToParent({
		type: frontendHubConfig.emitter.settingsChange,
		payload: {
			settings: {
				team: settingsState.team,
				settings: settingsState.settings,
				style: settingsState.style,
				language: settingsState.language,
			},
		},
	});
};
