import { frontendHubConfig } from "../config";

function sendToParent(payload) {
	if (window.parent) {
		window.parent.postMessage(payload, "*");
	}
}

export default {
	sendToParent,
	startTabbarNotification(message) {
		sendToParent({
			type: frontendHubConfig.emitter.startTabbarNotification,
			payload: { message },
		});
	},
	stopTabbarNotification() {
		sendToParent({ type: frontendHubConfig.emitter.stopTabbarNotification });
	},
	isParentHidden() {
		if (typeof document.webkitHidden !== "undefined") {
			return document.webkitHidden;
		}

		if (typeof document.msHidden !== "undefined") {
			return document.msHidden;
		}

		return document.hidden;
	},
};
