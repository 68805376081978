import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";

import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import nl from "react-intl/locale-data/nl";

import { credentialsConfig } from "./config";
import { Layout } from "./components/templates";
import { appController, frontendHubController } from "./controller";

addLocaleData(en);
addLocaleData(nl);

frontendHubController.initListener(appController.init);

if (process.env.NODE_ENV === "development") { // eslint-disable-line no-undef
	console.warn("passing data from hub will not work"); // eslint-disable-line no-console
	console.warn("turn of line 14-18 in src/index.js"); // eslint-disable-line no-console
	appController.init({
		apiKey: credentialsConfig.apiKey,
		identityId: "unique_user_identity_id",
	});
} else {
	ReactDOM.render(<Layout.TLoadScreen />, document.getElementById("root"));
}
